import React, { ReactNode } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { IPositionToken } from '../../../types/uniswapTokens';
import { ListPoolIcon } from '../../../modules/dashboard/lists/ListPoolIcon';
import { EMarketName } from '../../../ui-config/marketsConfig';

export type TxModalTitleProps = {
  title: ReactNode;
  symbol?: string;
  sx?: SxProps<Theme>;
};

export type TxModalTitleModernProps = {
  title: ReactNode;
  marketName?: EMarketName;
  tokenA?: IPositionToken;
  tokenB?: IPositionToken;
  fee?: number;
  sx?: SxProps<Theme>;
};

export const TxModalTitle = ({ title, symbol, sx }: TxModalTitleProps) => (
  <Typography variant="h2" sx={{ mb: 6, fontSize: '20px', ...sx }}>
    {title} {symbol ?? ''}
  </Typography>
);

export const TxModalTitleModern = ({ title, marketName, tokenA, tokenB, fee, sx }: TxModalTitleModernProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
    <Typography variant="h2" sx={{ margin: 0, fontSize: '20px',  ...sx }}>
      {title}
    </Typography>
    {tokenA && tokenB && (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <ListPoolIcon
          iconSymbolTokenA={tokenA.iconSymbol}
          iconSymbolTokenB={tokenB.iconSymbol}
          marketName={marketName}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="secondary16" noWrap>
            {tokenA.symbol}/{tokenB.symbol}
          </Typography>
          {Boolean(fee) && (
            <Typography variant="main16" sx={{ color: '#62677B', pl: '4px' }}>
              {fee}%
            </Typography>
          )}
        </Box>
      </Box>
    )}
  </Box>
);
